import React, { Suspense, lazy, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation
} from "react-router-dom";
import "./App.css";
import ReactGA from "react-ga4";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
// import APPBAR from "./components/appbar/Header";

const APPBAR = lazy(() => import("./components/appbar/Header"));
const Home = lazy(() => import("./components/home/Home"));
const QuickStart = lazy(() =>
	import("./components/services/QuickStart/QuickStart.js")
);
const Contact = lazy(() => import("./components/contact/Contact"));
const Footer = lazy(() => import("./components/footer/Footer"));
const SoftwareDevelopment = lazy(() =>
	import("./components/services/software/Software")
);
const MobileDevelopment = lazy(() =>
	import("./components/services/mobile/Mobile")
);
const DesignPage = lazy(() => import("./components/services/design/Design"));
const DataAnalytics = lazy(() =>
	import("./components/services/data-analytics/data")
);
const LegacySoftware = lazy(() =>
	import("./components/services/Legacy-software/legacy")
);
const AboutPage = lazy(() => import("./components/about/AboutPage"));
const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};
function App() {
	const TRACKING_ID = "G-YLJHJ620HS";
	ReactGA.initialize(TRACKING_ID);
	// ReactGA.send({ hitType: "pageview", page: "/" });
	ReactGA._gaCommandSendPageview(window.location.pathname);
	return (
		<Suspense
			fallback={
				<div
					style={{
						textAlign: "center",
						backgroundColor: "rgb(234, 225, 225)",
						height: "100vh",
						width: "100vw",
						overflow: "hidden",
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center"
						}}>
						<div className="loader"></div>
					</div>
				</div>
			}>
			<Helmet>
				<meta charSet="utf-8" />

				<meta
					name="viewport"
					content="width=device-width, initial-scale=1"
				/>
				<link
					rel="canonical"
					href="https://devanant.com/"
				/>
				<link
					rel="canonical"
					href="https://devanant.com/about"
				/>
				<link
					rel="canonical"
					href="https://devanant.com//software"
				/>
				<link
					rel="canonical"
					href="https://devanant.com/contact"
				/>
				<link
					rel="canonical"
					href="https://devanant.com/mobile-app"
				/>
				<link
					rel="canonical"
					href="https://devanant.com/quick-start"
				/>
				<link
					rel="canonical"
					href="https://devanant.com/design"
				/>
				<link
					rel="canonical"
					href="https://devanant.com/data-analysis"
				/>
				<link
					rel="canonical"
					href="https://devanant.com/legacy-software-modernization"
				/>
				<meta
					property="og:type"
					content="website"
				/>
				<title>DevAnant: Software Development Company</title>
				<meta
					property="og:url"
					content="https://devanant.com/about"
				/>
				<meta
					property="og:url"
					content="https://devanant.com//software"
				/>
				<meta
					property="og:url"
					content="https://devanant.com/contact"
				/>
				<meta
					property="og:url"
					content="https://devanant.com/mobile-app"
				/>
				<meta
					property="og:url"
					content="https://devanant.com/quick-start"
				/>
				<meta
					property="og:url"
					content="https://devanant.com/design"
				/>
				<meta
					property="og:url"
					content="https://devanant.com/data-analysis"
				/>
				<meta
					property="og:url"
					content="https://devanant.com/legacy-software-modernization"
				/>
				<meta
					property="og:url"
					content="https://devanant.com/"
				/>
				<meta
					name="description"
					content="DevAnant specializes in custom software development and consulting, offering tailor-made services in strategy, service design, legacy-software-modernization, software, and mobile app development "
				/>
				<meta
					property="og:site_name"
					content="DevAnant: Software Development Company"
				/>
				{/* Facebook tags */}

				<meta
					property="og:title"
					content="DevAnant: Software Development Company"
				/>
				<meta
					property="og:description"
					content="DevAnant specializes in custom software development and consulting, offering tailor-made services in strategy, service design, legacy-software-modernization, software, and mobile app development "
				/>
				{/* End Facebook tags */}
				{/* Twitter tags */}
				<meta
					name="twitter:title"
					content="DevAnant: Software Development Company"
				/>
				<meta
					name="twitter:description"
					content="DevAnant specializes in custom software development and consulting, offering tailor-made services in strategy, service design, legacy-software-modernization, software, and mobile app development "
				/>
				{/* End Twitter tags */}
			</Helmet>
			<Router>
				<ScrollToTop />
				<div className="whole-container">
					<APPBAR />
					<Routes>
						<Route
							exact
							path="/"
							element={<Home />}
						/>
						<Route
							exact
							strict
							path="/contact"
							element={<Contact />}
						/>
						{/* <Route path="/career" element={<Contact />} /> */}
						<Route
							exact
							strict
							path="/about"
							element={<AboutPage />}
						/>
						<Route
							exact
							strict
							path="/software"
							element={<SoftwareDevelopment />}
						/>
						<Route
							exact
							strict
							path="/mobile-app"
							element={<MobileDevelopment />}
						/>
						<Route
							exact
							strict
							path="/design"
							element={<DesignPage />}
						/>
						<Route
							exact
							strict
							path="/data-analysis"
							element={<DataAnalytics />}
						/>
						<Route
							exact
							strict
							path="/quick-start"
							element={<QuickStart />}
						/>
						<Route
							exact
							strict
							path="/privacy-policy"
							element={<PrivacyPolicy />}
						/>
						<Route
							exact
							path="/legacy-software-modernization"
							element={<LegacySoftware />}
						/>
					</Routes>
					<Footer />
				</div>
			</Router>
		</Suspense>
	);
}

export default App;
