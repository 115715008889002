// src/components/PrivacyPolicy.js
import React from "react";
import "./PrivacyPolicy.css"; // If you want to apply any custom styles
import { NavLink } from "react-router-dom";

const PrivacyPolicy = () => {
	return (
		<div className="privacy-policy-container">
			<h1>Privacy Policy</h1>
			<p>
				<strong>Last updated: December 30, 2024</strong>
			</p>

			<p>
				This Privacy Notice for Devanant App Private Limited (‘'we', 'us', or
				'our') describes how and why we might access, collect, store, use,
				and/or share (‘process’) your personal information when you use our
				services ('Services'), including when you:
			</p>
			<ul>
				<li>
					Visit our website at{" "}
					<a href="https://devanant.com/">https://devanant.com/</a>, or any
					website of ours that links to this Privacy Notice
				</li>
				<li>
					Engage with us in other related ways, including any sales, marketing,
					or events
				</li>
			</ul>
			<h2>Questions or concerns?</h2>
			<p>
				Reading this Privacy Notice will help you understand your privacy rights
				and choices. We are responsible for making decisions about how your
				personal information is processed. If you do not agree with our policies
				and practices, please do not use our Services.
			</p>

			<h2>SUMMARY OF KEY POINTS</h2>
			<p>
				This summary provides key points from our Privacy Notice, but you can
				find out more details about any of these topics by clicking the link
				following each key point or by using our table of contents below to find
				the section you are looking for.
			</p>

			<h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
			<p>
				<strong>Personal information you disclose to us:</strong> We collect
				personal information that you voluntarily provide to us when you express
				an interest in obtaining information about us or our products and
				Services, when you participate in activities on the Services, or
				otherwise when you contact us.
			</p>
			<p>
				<strong>Sensitive Information:</strong> We do not process sensitive
				information.
			</p>

			<p>
				<strong>Information automatically collected:</strong> Some information
				is collected automatically when you visit, use, or navigate the
				Services, like your IP address, browser characteristics, operating
				system, location, etc.
			</p>

			<h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
			<p>
				We process your personal information to provide, improve, and administer
				our Services, communicate with you, for security and fraud prevention,
				and to comply with law. We may also process your information for other
				purposes with your consent.
			</p>

			<h3>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
			<p>
				We may share your information in specific situations such as with
				third-party vendors and service providers who perform services for us.
				Examples of third-party services include:
			</p>
			<ul>
				<li>Communicating and chatting with users - Tawk.to</li>
				<li>Web and Mobile Analytics - Google Analytics, Hotjar</li>
			</ul>

			<h3>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
			<p>
				We may use cookies and similar tracking technologies to gather
				information when you interact with our Services. We also allow third
				parties to use tracking technologies for analytics and advertising
				purposes.
			</p>

			<h3>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
			<p>
				We keep your personal information for as long as necessary to fulfill
				the purposes outlined in this Privacy Notice unless otherwise required
				by law.
			</p>

			<h3>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
			<p>
				We have implemented technical and organisational security measures
				designed to protect your personal information. However, no electronic
				transmission is 100% secure.
			</p>

			<h3>7. DO WE COLLECT INFORMATION FROM MINORS?</h3>
			<p>
				We do not knowingly collect data from children under 18 years of age.
			</p>

			<h3>8. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
			<p>
				You may review, change, or terminate your account at any time, depending
				on your country, province, or state of residence.
			</p>

			<h3>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
			<p>
				We do not currently respond to Do-Not-Track (DNT) browser signals or any
				other mechanism that communicates your choice not to be tracked online.
			</p>

			<h3>10. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
			<p>
				Yes, we will update this Privacy Notice as necessary to stay compliant
				with relevant laws.
			</p>

			<h3>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
			<p>
				You may contact us by post at:
				<br />
				Devanant App Private Limited, Punjab, India.
			</p>

			<h3>
				12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
			</h3>
			<p>
				To request to review, update, or delete your personal information,
				please visit{" "}
				<NavLink to="/contact">https://devanant.com/contact</NavLink>
			</p>
		</div>
	);
};

export default PrivacyPolicy;
